import { getApp, getApps, initializeApp } from 'firebase/app';
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  User as FirebaseUser,
  getAuth,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  verifyPasswordResetCode,
} from 'firebase/auth';
import { collection, getDocs, getFirestore } from 'firebase/firestore';
import {
  getFunctions,
  httpsCallable,
  httpsCallableFromURL,
} from 'firebase/functions';
import { getBytes, getStorage, ref } from 'firebase/storage';
import { md5 } from 'js-md5';
import { FIRMWARE, PLATFORM } from './const';
import FIREBASE_CONFIG from './firebase_config';
import publicFirmware from './publicFirmware.json';
import publicInstaller from './publicInstaller.json';

const app = getApps().length ? getApps()[0] : initializeApp(FIREBASE_CONFIG);
const funcs = getFunctions();

function callable(name: string) {
  if (typeof window === 'undefined') {
    return httpsCallable(funcs, name);
  } else if (window.location.host.includes('localhost')) {
    return httpsCallable(funcs, name);
  } else {
    const url = 'https://' + window.location.host + '/' + name;
    return httpsCallableFromURL(funcs, url);
  }
}

var verifyOnce = false;

async function verifyEmail(user: FirebaseUser) {
  if (verifyOnce) {
    console.log(
      'skipping user email verification as it has already been sent\n'
    );
    return;
  }

  verifyOnce = true;
  console.log('resend user email verification\n');

  try {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };
    await sendEmailVerification(user, actionCodeSettings);
    console.log('sendEmailVerification success');
    //getAuth(app).signOut();
  } catch (e: any) {
    console.log(e);
    if (e.code === 'auth/too-many-requests') {
      console.log('waiting on email timeout..');
    } else {
      console.log(e);
    }
  }
}

async function resetPassword(
  newPw: string,
  oobCode: string,
  continueUrl: string
) {
  //await verifyPasswordResetCode(FB.auth, oobCode);
  console.log(FB.auth, oobCode, newPw);
  await confirmPasswordReset(FB.auth, oobCode, newPw);
  window.location.href = continueUrl;
}

async function redeem(guid: string) {
  const gcf_key = callable('key');

  console.log('redeeming ' + guid);

  const res: any = await gcf_key({ action: 'redeem', guid: guid });

  console.log('redeemed ', res);

  const prefix = 'Key redeemed for ';
  var message = res.data.message;

  if (message.includes(prefix)) {
    const codetext = message.replace(prefix, '').trim();
    const codes = codetext.split(',');
    console.log(codetext, codes);
  }

  return res.data;
}

const FB = {
  conf: FIREBASE_CONFIG,
  app: app,
  auth: getAuth(app),
  firestore: getFirestore(app),
  storage: getStorage(app),
  funcs: funcs,
  ping: callable('ping'),
  product: callable('product'),
  key: callable('key'),
  user: callable('user'),
  verifyEmail: verifyEmail,
  email: callable('support'),
  resetPassword: resetPassword,
  redeem: redeem,
  signup: async function (email: string, pw: string) {
    const user = await createUserWithEmailAndPassword(getAuth(app), email, pw);
    const res = await callable('user')({ action: 'signup', email: email });
    console.log(res);
    return user;
  },
  reset: async function (email: string) {
    const actionCodeSettings = {
      url: window.location.href,
      handleCodeInApp: true,
    };

    await sendPasswordResetEmail(getAuth(app), email, actionCodeSettings);
    const res = await callable('user')({ action: 'validate', email: email });
    console.log(res);
  },
};

export default FB;

export interface Product {
  name: string;
  md5: string;
  bucket: string;
  git_sha1: string;
  cpu: string;
  os: string;
  version: number;
  code: string;
  format: string;
  file: string;
  guid: string;
  product_type: string;
  platform: string;
  release: string;
  fullname: string;
  url: string;
  getBin: () => Promise<ArrayBuffer>;
}

export async function getPing() {
  return (await FB.ping({ input: 'input' })).data;
}

async function getFirebaseProduct(type: string, release: string) {
  const path = `release_${type}_${release}`;
  const fws: any[] = [];

  try {
    let col = collection(FB.firestore, path);
    let docs = await getDocs(col);
    docs.forEach((doc) => fws.push(doc.data()));
  } catch (e) {
    //console.log('unable to load:', path);
  }

  return fws;
}

function fw2obj(release: string, fw: any, stat: boolean = false) {
  const name = FIRMWARE[fw.name]?.name || fw.name;

  async function getBin() {
    if (stat) {
      console.log('getting firmware from webserver\n');
      const r = await fetch('/products/' + fw.file);
      const b = await r.arrayBuffer();
      const c = md5(b);
      const d = fw.md5 ? fw.md5 : fw.guid;
      if (c.toUpperCase() !== d.toUpperCase()) {
        console.log('checksums do not match', c, d);
      }
      return b;
    } else {
      console.log('getting firmware from bucket\n');
      const r = ref(FB.storage, fw.bucket);
      const b = await getBytes(r);
      const c = md5(b);
      const d = fw.md5 ? fw.md5 : fw.guid;
      if (c.toUpperCase() !== d.toUpperCase()) {
        console.log('checksums do not match', c, d);
      }
      return b;
    }
  }

  return {
    release: release,
    name: name,
    md5: fw.md5 ? fw.md5 : fw.guid,
    bucket: fw.bucket,
    git_sha1: fw.git_sha1,
    cpu: fw.cpu,
    os: fw.os,
    version: fw.version,
    code: fw.code,
    format: fw.format,
    platform: getPlatField(fw, 'name'),
    file: fw.file,
    guid: fw.guid,
    product_type: fw.product_type,
    image: 'panels/' + name.split(' ').join('_').toLowerCase() + '.png',
    fullname:
      release === 'public'
        ? name
        : name + ' (' + release + ' ' + fw.version + ')',
    shortDesc: FIRMWARE[name]?.shortDesc || name,
    medDesc: FIRMWARE[name]?.medDesc || name,
    longDesc: FIRMWARE[name]?.longDesc || name,
    shopUrl: FIRMWARE[name]?.shopUrl || 'https://www.noiseengineering.us',
    manualUrl:
      FIRMWARE[name]?.manualUrl || 'https://manuals.noiseengineering.us',
    youTubeUrl:
      FIRMWARE[name]?.youTubeUrl || 'https://www.youtube.com/noiseengineering',
    url: '',
    getBin: getBin,
  };
}

async function getProduct(type: string, release: string) {
  var fws = await getFirebaseProduct(type, release);

  return fws.map((fw: Product) => fw2obj(release, fw));
}

function filterPlugins(lics: string[], plugins: Product[]) {
  const allLics = [...lics, 'VIV', 'SIV', 'RV'];

  return plugins.filter((p: Product) => allLics.includes(p.code));
}

export async function getPlugins(lics: string[]) {
  const pls = [];
  const ins = [];

  const p = getProduct('plugin', 'public');
  const b = getProduct('plugin', 'beta');
  const t = getProduct('plugin', 'test');

  const all = [...(await p), ...(await b), ...(await t)];

  for (const p of all) {
    if (p.code == 'PLGM') {
      ins.push(p);
    } else {
      pls.push(p);
    }
  }

  return { plugins: filterPlugins(lics, pls), installers: ins };
}

export async function getFirmwares() {
  const p = getProduct('firmware', 'public');
  const b = getProduct('firmware', 'beta');
  const t = getProduct('firmware', 'test');
  const f = getProduct('firmware', 'factory');
  return [...(await p), ...(await b), ...(await t), ...(await f)];
}

export function getStaticFirmware(): Product[] {
  return publicFirmware.map((fw: Product) => fw2obj('public', fw, true));
}

export function getStaticInstaller(): Product[] {
  return publicInstaller.map((fw: Product) => fw2obj('public', fw, true));
}

export interface Platform {
  code: string;
  name: string;
  image: string;
  helpImage: string;
  desc: string;
  shopUrl: string;
  shortDesc: string;
}

function getPlatField(fw: Product, name: string) {
  return PLATFORM[fw.format] ? PLATFORM[fw.format][name] : fw.format;
}

export function getPlatforms(firmwares: Product[]) {
  let idx: any = {};
  let plats: Platform[] = [];

  for (let x of firmwares) {
    if (x.os === 'lacerti' && !idx[x.format]) {
      idx[x.format] = x.format;

      const name = getPlatField(x, 'name');

      plats.push({
        code: x.format,
        name: name,
        image: 'panels/' + name.toLowerCase() + '.png',
        helpImage: 'panels/help_' + name.toLowerCase() + '.png',
        desc: getPlatField(x, 'desc'),
        shopUrl: getPlatField(x, 'shopUrl'),
        shortDesc: getPlatField(x, 'shortDesc'),
      });
    }
  }

  return plats;
}
